
<template>
  <div>
    <div class="carelist">
      <div class="header">
        <el-button size="mini" plain type="primary" @click="()=>this.$router.back()">{{$t("care.back")}}</el-button>
        <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="getList()"></el-button>
        <el-button size="mini" plain type="primary" icon="el-icon-plus"  @click="handleAdd()"></el-button>
        <el-button size="mini" :loading="pulling" plain type="primary"  @click="handlePull()">{{$t('care.sync_tpls')}}</el-button>
        <div class="search">
          <span>{{$t("care.search_title")}}：</span>
          <div>
            <el-select @change="onSearch" clearable size="small" style="width: 100px" v-model="search.status" :placeholder="$t('care.value_str.select')">
              <el-option :label="$t('care.tpl.status_on')" :value="1"></el-option>
              <el-option :label="$t('care.tpl.status_off')" :value="0"></el-option>
            </el-select>
          </div>
          <div>
            <el-input size="small"  :placeholder="$t('care.search_title')" v-model="search.search" class="input-with-select">
              <el-button  @click="onSearch" slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
        </div>

      </div>
      <div class="content">
        <el-empty :description="$t('care.notSetCare')" v-if="hadGetlist&&tableData.length===0"></el-empty>
        <el-table
            v-else
            v-loading="spinning"
            class="table"
            row-key="id"
            :data="tableData"
            :height="tableHeight"
            highlight-current-row
        >
          <el-table-column
              prop="name"
              :label="`${$t('care.tpl.name')}/${$t('care.tpl.language')}`"
          >
            <template slot-scope="scope">
              <div class="avatar">
                <a-tag style="margin-left: 8px;" color="blue">
                  {{scope.row.name}}
                </a-tag>
                <i style="font-size:small;">({{scope.row.language}})</i>
              </div>
            </template>
          </el-table-column>


          <el-table-column
              prop="alias"
              :label="$t('care.tpl.alias')"
          >
            <template slot-scope="scope">
              <div class="avatar">
                <el-button size="mini" type="primary" @click="onEditAlias(scope.row)" >
                  <i @click="onEditAlias(scope.row)" class="el-icon-edit">{{scope.row.alias}}</i>
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="name"
              :label="$t('care.tpls.parm_mapping_edit')"
          >
            <template slot-scope="scope">
              <div class="avatar">
                <el-button size="mini" type="primary" @click="onEditParmMappings(scope.row)" >
                  <i class="el-icon-edit">{{$t('care.tpls.parm_mapping_edit')}}</i>
                </el-button>
              </div>
            </template>
          </el-table-column>
<!--          <el-table-column-->
<!--              prop="language"-->
<!--              :label="$t('care.tpl.language')"-->
<!--          >-->
<!--            <template slot-scope="scope">-->
<!--              {{scope.row.language}}-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
              prop="status"
              :label="$t('care.tpl.status')"
          >
            <template slot-scope="scope">
              <a-tag v-if="scope.row.status===1" color="blue">{{$t('care.tpl.status_on')}}</a-tag>
              <a-tag v-else >{{$t('care.tpl.status_off')}}</a-tag>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('care.ops')"
          >
            <template slot-scope="scope">
              <div class="options">
                <div>
                  <el-button
                      :loading="scope.row.delete_loading"
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="handleDelete(scope.$index, scope.row)"></el-button>
                </div>
                <!--                <div>-->
                <!--                  <el-button-->
                <!--                      size="mini"-->
                <!--                      type="primary"-->
                <!--                      icon="el-icon-view"-->
                <!--                      @click="onDblclick(scope.row)">{{$t('care.preview')}}</el-button>-->
                <!--                </div>-->
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="body"
              :label="$t('care.tpl.body')"
          >
            <template slot-scope="scope">
              <TplCard :inner="true" :visible="true" :tpl="scope.row.data"/>
            </template>
          </el-table-column>

        </el-table>
        <el-pagination
            small
            :page-size="pageSize"
            layout="prev, pager, next"
            :current-page.sync="page"
            @current-change="pageChange"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <el-drawer
        size="80%"
    :title="$t('care.tpls.select_theme')"
    :wrapperClosable="false"
    :visible.sync="themeTabVisible"
    @close="themeTabVisible=false"
    append-to-body>
          <div style="height: 100%;width: 100%;">
            <div style="font-weight: bolder;text-align:center;height: 50px;font-size: 30px;color: #f66161;">
              {{$t('care.tpls.dbclick_select')}}
            </div>
            <div  style="height:calc(99% - 50px);width: 100%;">
              <iframe
                  style="width: 100%;height:100%;border:0;border-top: 1px solid #ddd;overflow: hidden;"
                  :src="`https://tto-subject-tto-subject.apps.v8eq.hk.topkee.top/subject?jwtToken=${jwtToken}&locale=${locale}`"
                  frameborder="0"
              ></iframe>
            </div>
          </div>

    </el-drawer>
    <el-drawer
        size="90%"
        :title="$t('care.tpls.title')"
        :wrapperClosable="false"
        :visible.sync="addCareVisible"
        @close="initForm"
        append-to-body>
      <div class="tpl_ed_warp">
        <div style="flex: 1;min-width:500px;height: 100%; padding: 20px;border-right: 1px solid #ccc;">
          <div>

            <el-form :model="themeForm" :label-width="formLabelWidth">
              <el-form-item :label="$t('care.tpl.theme_content')" prop="theme" required>
                <div class="formval">
                  <el-input
                      :disabled="add_step!==1"
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 10}"
                      v-model="themeForm.theme"></el-input>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button :disabled="add_step!==1" type="primary" @click="themeTabVisible=true">1、{{$t('care.tpls.select_theme')}}</el-button>
              </el-form-item>
              <el-form-item :label="$t('care.tpl.prompt_content')" prop="prompt" required>
                <div class="formval">
                  <el-input
                      :disabled="add_step!==1"
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 10}"
                      v-model="themeForm.prompt"></el-input>
                </div>
                <div style="color: #777;font-size: smaller;">{{$t('care.tpl.prompt_edit_tips')}}</div>
              </el-form-item>
              <el-form-item>
                <el-button :disabled="add_step!==1||themeForm.prompt===''||themeForm.theme===''" type="primary" @click="onCreateWhatsappTpl">2、{{$t('care.tpl.create_by_theme')}}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div style="padding: 20px;width: 900px;height: 100%;overflow-y: auto;">
          <el-steps :active="add_step" simple finish-status="finish">
            <el-step :title="$t('care.tpls.steps.setting')" ></el-step>
            <el-step :title="$t('care.tpls.steps.edit')" ></el-step>
            <el-step :title="$t('care.tpls.steps.submit')" ></el-step>
          </el-steps>
          <div v-if="add_step===0">
            <div style="font-size: 20px;font-weight: bolder;margin: 20px 0;">{{$t('care.tpls.setting_tpl_title')}}</div>
            <div>
              <div style="height:40px;display: flex;flex-direction: row;text-align: center; justify-content: space-around;border: 1px solid #aaa;">
                <div :class="{'selected':form.category==='MARKETING'}" style="line-height:40px;flex: 1;text-align: center; cursor:pointer; border-right: 1px solid #aaa;" @click="form.category='MARKETING'"><i class="el-icon-goods"></i>{{$t('care.tpls.cats.marketing')}}</div>
                <div :class="{'selected':form.category==='AUTHENTICATION'}" style="line-height:40px;flex: 1;text-align: center;cursor:pointer;" @click="form.category='AUTHENTICATION'"><i class="el-icon-key"></i>{{$t('care.tpls.cats.auth')}}</div>
              </div>
              <div style="min-height: 45px;margin-bottom: 20px;">
                <div v-if="form.category==='MARKETING'">
                  {{$t('care.tpls.cats.marketing_desc')}}
                </div>
                <div v-else-if="form.category==='AUTHENTICATION'">
                  {{$t('care.tpls.cats.auth_desc')}}
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="add_step===1||add_step===2" style="width: 100%;">
            <el-form :model="form" :rules="rules" :label-width="formLabelWidth" style="max-width: 800px;margin: 20px auto;">
              <!--            <el-alert-->
              <!--                title="暫時不支援新增模板頭部，如有需要，請自行到Facebook官方網頁添加"-->
              <!--                type="warning"-->
              <!--                effect="dark"-->
              <!--                show-icon-->
              <!--                center-->
              <!--                :closable="false"-->
              <!--                style="margin-bottom: 40px;"-->
              <!--            >-->
              <!--            </el-alert>-->
              <el-form-item :label="$t('care.careform.name')" prop="name">
                <div class="formval">
                  <el-input :maxlength="2000" show-word-limit :placeholder="$t('care.tpls.name_placeholder')"  v-model.trim="form.name"></el-input>
                  <el-select v-model="form.language" placeholder="language">
                    <el-option
                        v-for="item in langOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <el-alert
                    style="margin-top:14px;"
                    v-if="showNameError"
                    :description="$t('care.tpls.name_placeholder')"
                    show-icon
                    :closable="false"
                    type="error">
                </el-alert>
              </el-form-item>

              <el-form-item :label="$t('care.tpls.params_type')">
                <div class="formval">
                  <el-select v-model="form.parameter_format">
                    <el-option :label="$t('care.tpls.params_name')" value="NAMED"></el-option>
                    <el-option :label="$t('care.tpls.params_num')" value="POSITIONAL"></el-option>
                  </el-select>
                </div>
              </el-form-item>

              <el-form-item :label="$t('care.tpls.header_title')">
                <div class="formval">
                  <el-select v-model="form.componentsObj.HEADER_TYPE">
                    <el-option
                        v-for="item in headerTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"></el-option>
                  </el-select>
                </div>
                <div  style="padding-top: 10px;" v-if="form.componentsObj.HEADER_TYPE==='TEXT'">
                  <el-input :maxlength="60" show-word-limit  :placeholder="$t('care.tpls.header_placeholder')" v-model="form.componentsObj.HEADER.text"></el-input>

                  <div class="textarea_tools">
                    <div @click="onTextdynamicParm('header')">
                      <el-tooltip class="item" effect="dark" :content="$t('care.tpls.body_insert_parms_tips')" placement="top">
                        <strong>+ {{$t('care.tpls.body_insert_parms_tit')}}</strong>
                      </el-tooltip>
                    </div>
                  </div>
                  <i v-if="!showHeaderParmsErr">{{$t('care.tpls.header_tips')}}</i>

                  <div v-else style="margin-top: 14px;">
                    <el-alert
                        v-if="form.parameter_format==='NAMED'"
                        :description="$t('care.tpls.header_err_named')"
                        show-icon
                        :closable="false"
                        type="error">
                    </el-alert>
                    <el-alert
                        v-else
                        :description="$t('care.tpls.header_err_num')"
                        show-icon
                        :closable="false"
                        type="error">
                    </el-alert>
                  </div>
                </div>
                <div style="margin-top: 8px;" v-else-if="form.componentsObj.HEADER_TYPE==='IMAGE'">
                  <el-upload
                      accept="image/png, image/jpeg"
                      class="upload-demo"
                      action=""
                      :http-request="onUpload"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      :on-exceed="handleExceed"
                      :file-list="fileList">
                    <el-button size="small" type="primary">{{$t('care.tpls.header_up_img')}}</el-button>
                    <!--                      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
                  </el-upload>
                </div>
                <div style="margin-top: 8px;" v-else-if="form.componentsObj.HEADER_TYPE==='VIDEO'">
                  <el-upload
                      accept="video/mp4"
                      class="upload-demo"
                      action=""
                      :http-request="onUpload"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      :on-exceed="handleExceed"
                      :file-list="fileList">
                    <el-button size="small" type="primary">{{$t('care.tpls.header_up_vod')}}</el-button>
                    <!--                    <div slot="tip" class="el-upload__tip">只能上传mp4文件</div>-->
                  </el-upload>
                </div>
                <div style="margin-top: 8px;" v-else-if="form.componentsObj.HEADER_TYPE==='DOCUMENT'">
                  <el-upload
                      accept="application/pdf"
                      class="upload-demo"
                      action=""
                      :http-request="onUpload"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      :on-exceed="handleExceed"
                      :file-list="fileList">
                    <el-button size="small" type="primary">{{$t('care.tpls.header_up_pdf')}}</el-button>
                    <!--                    <div slot="tip" class="el-upload__tip">只能上传pdf文件</div>-->
                  </el-upload>
                </div>


              </el-form-item>




              <el-form-item :label="$t('care.careform.body_text')"  prop="body_text">
                <div class="formval">
                  <el-input ref="textArea"
                            @mouseup="getSelectedText"
                            type="textarea"
                            :rows="8"
                            maxlength="1000"
                            show-word-limit
                            :placeholder="$t('care.tpls.body_placeholder')"
                            v-model="form.body_text"></el-input>

                </div>
                <div class="textarea_tools">
                  <div @click="onTextBold">
                    <el-tooltip class="item" effect="dark" :content="$t('care.tpls.body_bold')" placement="top">
                      <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M728.064 450.901333A264.192 264.192 0 0 0 512 34.133333H204.8a68.266667 68.266667 0 0 0-68.266667 68.266667v819.2a68.266667 68.266667 0 0 0 68.266667 68.266667h409.6a281.6 281.6 0 0 0 113.664-538.965334zM512 170.666667a128 128 0 0 1 0 256H273.066667V170.666667z m102.4 682.666666H273.066667v-290.133333h341.333333a145.066667 145.066667 0 0 1 0 290.133333z" fill="#707070" ></path></svg>
                    </el-tooltip>
                  </div>
                  <div @click="onTextItalics">
                    <el-tooltip class="item" effect="dark" :content="$t('care.tpls.body_italics')" placement="top">
                      <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M867.693714 127.926857V0H440.978286v127.926857H561.810286l-251.904 768h-153.6V1024H583.021714v-127.926857H457.947429l251.757714-768z" fill="#707070" ></path></svg>
                    </el-tooltip>
                  </div>
                  <div @click="onTextDeleted">
                    <el-tooltip class="item" effect="dark" :content="$t('care.tpls.body_deleted_line')" placement="top">
                      <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M1024 511.81H687.11c-38.48-16.41-94.03-35.49-167.45-57.37-77.09-22.34-126.25-39.09-146.36-50.27-45.8-24.57-68.14-56.98-68.14-97.18 0-45.82 18.98-79.32 56.98-101.66 33.5-20.11 79.32-29.07 138.52-29.07 64.8 0 115.07 13.41 150.82 42.45 34.64 27.93 56.98 70.39 67.05 128.48H809c-7.82-83.77-37.98-147.45-91.61-189.91C666 115.94 594.5 95.83 505.14 95.83c-82.68 0-150.82 17.89-203.34 53.64-59.2 37.98-88.25 92.73-88.25 161.98 0 67.05 30.16 118.43 91.61 154.18 19.87 10.38 61.41 26.15 123.58 46.19H0v93.09h681.64c35.63 26.24 54.75 59.59 54.75 100.93 0 42.43-20.11 75.95-60.32 100.55-40.23 24.57-93.84 36.86-158.66 36.86-71.5 0-125.11-15.64-161.98-44.68-40.23-32.41-64.8-83.8-72.61-153.07h-90.5c6.7 98.32 41.34 170.93 103.91 218.98 53.61 40.2 127.34 60.32 221.18 60.32 94.98 0 169.82-20.11 225.68-59.2 55.86-40.23 83.8-96.09 83.8-165.34 0-35.82-8.24-67.53-24.42-95.34H1024v-93.11z" fill="#707070"></path></svg>
                    </el-tooltip>
                  </div>
                  <div @click="onTextdynamicParm('body')">
                    <el-tooltip class="item" effect="dark" :content="$t('care.tpls.body_insert_parms_tips')" placement="top">
                      <strong>+ {{$t('care.tpls.body_insert_parms_tit')}}</strong>
                    </el-tooltip>
                  </div>
                </div>
                <div v-if="showBodyParmsErr">
                  <el-alert
                      v-if="form.parameter_format==='NAMED'"
                      :description="$t('care.tpls.body_err_named')"
                      show-icon
                      :closable="false"
                      type="error">
                  </el-alert>
                  <el-alert
                      v-else
                      :description="$t('care.tpls.body_err_num')"
                      show-icon
                      :closable="false"
                      type="error">
                  </el-alert>
                </div>

                <div v-if="!showBodyParmsErr&&!showHeaderParmsErr">
                  <div v-if="body_parms.length||header_parms.length">{{ $t('care.tpls.all_parms_title') }}：{{body_parms.concat(header_parms)}}</div>
                  <el-alert
                      v-if="body_parms.length||header_parms.length"
                      :description="$t('care.tpls.all_parms_tips')"
                      show-icon
                      :closable="false"
                      type="warning">
                  </el-alert>
                </div>
              </el-form-item>
              <!--            <el-form-item :label="$t('care.careform.body_example')">-->
              <!--              <div class="formval">-->
              <!--                <el-input :placeholder="'提供一個替換{{1}}的範例文本'"  v-model="form.body_example"></el-input>-->
              <!--              </div>-->
              <!--            </el-form-item>-->

              <el-form-item :label="$t('care.careform.footer_test')">
                <div class="formval">
                  <el-input :maxlength="60" show-word-limit v-model="form.footer_test"></el-input>
                </div>

                <el-alert
                    style="margin-top: 14px;"
                    v-if="showFootParmsErr"
                    :description="$t('care.tpls.foot_description')"
                    show-icon
                    :closable="false"
                    type="error">
                </el-alert>
                <i v-else>{{$t('care.tpls.foot_normal_tips')}}</i>

              </el-form-item>
              <el-form-item :label="$t('care.careform.bottons')">
                <div class="formval" style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;">
                  <div  style="margin-bottom: 10px; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;">
                    <div style="margin-left: 90px; width:150px;font-size: large; ">{{$t('care.tpls.btns_title_type')}}</div>
                    <div style="width:200px;font-size: large; ">{{$t('care.tpls.btns_title_text')}}</div>
                    <div style="width:150px; font-size: large;">{{$t('care.tpls.btns_title_tel')}}</div>
                  </div>
                  <div
                      style="margin-bottom: 10px; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;"
                      v-for="(btn,index) in form.bottons"
                      :key="index">
                    <el-button
                        style="margin-right: 10px; "
                        type="danger"
                        icon="el-icon-delete"
                        @click="deleteBtn(index)"></el-button>
                    <el-select
                        :disabled="btn.type==='STOP_PROMOTIONS'"
                        @change="onBtnTypeChange(index,btn.type)"
                        v-model="btn.type"
                        style="width:150px;margin-right: 10px; ">
                      <el-option
                          v-for="item in btntypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"></el-option>
                    </el-select>
                    <el-input v-if="btn.type!=='STOP_PROMOTIONS'"  :maxlength="25" show-word-limit style="width:200px;margin-right: 10px; " v-model="btn.text"></el-input>
                    <el-select
                        v-else
                        v-model="btn.text"
                        style="width:200px;margin-right: 10px;">
                      <el-option
                          v-for="item in stopPromotionTextOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"></el-option>
                    </el-select>
                    <el-input :maxlength="25" show-word-limit placeholder="e.g., +85239087888"  style="width:180px; " v-if="btn.type==='PHONE_NUMBER'" v-model="btn.phone_number"></el-input>
                    <el-input :maxlength="2000" show-word-limit placeholder="e.g., https://www.topkee.com.hk/"  style="width:180px; " v-if="btn.type==='URL'"  v-model="btn.url"></el-input>

                  </div>
                  <el-button
                      v-if="form.bottons.length<10"
                      icon="el-icon-plus"
                      @click="addBtn()"></el-button>
                  <div v-else>{{$t('care.tpls.btns_limit_tips')}}</div>
                  <i style="font-size: smaller;color: #888;">{{$t('care.tpls.tips_stop_promotion')}}</i>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div v-else>
            {{$t('care.tpls.btns_submit')}}
          </div>
          <div class="foot foot_btns">
            <el-button @click="addCareVisible=false">{{$t('care.tpls.btns_cancel')}}</el-button>
            <div class="foot_btns">
              <el-button @click="add_step--" v-if="add_step>0">{{$t('care.tpls.btns_back')}}</el-button>
              <el-button type="primary" @click="onNestStep"  v-if="add_step===0">{{$t('care.tpls.btns_nest')}}</el-button>
              <el-button type="primary" :loading="addLoading" @click="save"  v-if="add_step>0" >{{$t('care.tpls.btns_review')}}</el-button>
            </div>
          </div>
        </div>
      </div>

    </el-drawer>
    <TplCard v-if="cardVisible" :visible="cardVisible" :tpl="viewRow" @onClose="cardVisible=false" />
    <el-dialog
        :close-on-click-modal="false"
        :title="$t('care.tpl.alias')"
        :visible.sync="editVisible"
        width="600px"
        append-to-body>
      <el-form :model="editForm" :label-width="formLabelWidth" style="max-width: 860px">
        <el-form-item :label="$t('care.tpl.alias')" prop="alias" required>
          <div class="formval">
            <el-input  v-model="editForm.alias"></el-input>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">{{$t('care.messenger.cancel')}}</el-button>
        <el-button type="primary" @click="onSaveAlias">{{$t('care.messenger.sure')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog
        v-if="parmmappingTplParms"
        :close-on-click-modal="false"
        :title="$t('care.tpls.parm_mapping_edit')"
        :visible.sync="parmmappingVisible"
        width="600px"
        @close="onCancelSaveParmmappings"
        append-to-body>
      <el-alert
          :title="$t('care.tpls.parm_mapping_edit')"
          type="success"
          :description="$t('care.tpls.parm_mapping_tips')"
          style="margin-bottom: 30px;"
          show-icon>
      </el-alert>
      <el-form :model="parmmappingForm" :label-width="formLabelWidth" style="max-width: 860px">
        <el-form-item v-for="parm in parmmappingTplParms" :key="parm" :label="parm" :prop="parm" required>
          <div class="formval">
            <el-input v-model="parmmappingForm[parm]"></el-input>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCancelSaveParmmappings">{{$t('care.messenger.cancel')}}</el-button>
        <el-button type="primary" @click="onSaveParmmappings">{{$t('care.messenger.sure')}}</el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import "./index.less";
import { message } from 'ant-design-vue';
import careMixin from "../../../mixins/careMixin";
import {
  addMessengerTpl,
  deleteMessengerTpl,
  getMessengerTplsByPage, pullMessengerTplAlias,
  pullMessengerTpls, pullTplParmMappings
} from "../../../libs/apis/messenger";
import TplCard from "@/pages/admin/components/TplCard.vue";
import {whatsappResumableUpload} from "../../../libs/apis/whatsapp";
import {postCreateWhatsappTplAI} from "../../../libs/apis/bot";
import { getAuthor } from '@/libs/utils/author';
import { getCareLang } from '@/libs/utils/langStore';

export default{
  data(){


    const def_form=this.getDefForm()
    return {
      add_step: 0,
      cardVisible:false,
      addCareVisible:false,
      addLoading:false,
      pulling:false,
      visible:false,
      spinning:false,
      hadGetlist:false,
      tableHeight:document.documentElement.clientHeight - 100,
      tableData:[],
      deleteRow:{},
      groupEditVisible:false,
      addCareLoading:false,
      formLabelWidth:'120px',
      group:{},
      search:{
        search:null,
        status:null,
      },
      headerTypeOptions:[
        {
          label: this.$t('care.tpls.header_types_none'),
          value:null,
        },
          {
          label: this.$t('care.tpls.header_types_txt'),
          value:'TEXT',
        },
          {
          label:this.$t('care.tpls.header_types_img'),
          value:'IMAGE',
        },{
          label:this.$t('care.tpls.header_types_vod'),
          value:'VIDEO',
        },{
          label:this.$t('care.tpls.header_types_doc'),
          value:'DOCUMENT',
        },{
          label:this.$t('care.tpls.header_types_location'),
          value:'LOCATION',
        },

      ],
      form:def_form,
      rules:{
        name: [
          { required: true, message: '請填寫模板名', trigger: 'blur' }
        ],
        body_text: [
          { required: true, message: '請填寫body', trigger: 'blur' }
        ],
        // body_example: [
        //   { required: true, message: '請填寫body參數範例', trigger: 'blur' }
        // ],

      },
      page:1,
      pageSize:10,
      total:0,
      selectRow:null,
      viewRow:{},
      btntypeOptions:[
        {
          label:this.$t('care.tpls.stop_promotions'),
          value:'STOP_PROMOTIONS',
        },{
          label:this.$t('care.tpls.quick_reply'),
          value:'QUICK_REPLY',
        },{
          label:this.$t('care.tpls.vist_websit'),
          value:'URL',
        },{
          label:this.$t('care.tpls.call_phone'),
          value:'PHONE_NUMBER',
        },
      ],
      stopPromotionTextOptions:[
        {
          label:this.$t('care.tpls.stop_promotions'),
          value:this.$t('care.tpls.stop_promotions'),
        },{
          label:this.$t('care.tpls.stop_promotions_report'),
          value:this.$t('care.tpls.stop_promotions_report'),
        },{
          label:this.$t('care.tpls.stop_promotions_refuse'),
          value:this.$t('care.tpls.stop_promotions_refuse'),
        },{
          label:this.$t('care.tpls.stop_promotions_unsubscription'),
          value:this.$t('care.tpls.stop_promotions_unsubscription'),
        },
      ],
      langOptions:[
        {
          label:'简体中文',
          value:'zh_CN',
        },{
          label:'繁體中文',
          value:'zh_HK',
        },{
          label:'English',
          value:'en',
        },
      ],
      selectedText: '',
      showBodyParmsErr:false,
      showHeaderParmsErr:false,
      showNameError:false,
      showFootParmsErr:false,
      header_parms:[],
      body_parms:[],

      fileList:[],
      editForm:{
        alias:null,
      },
      editTpl:{},
      editVisible:false,

      parmmappingVisible:false,
      parmmappingForm:{

      },
      parmmappingRow:null,
      themeForm: {
        theme:'',
        prompt:this.getPrompt(),
      },
      themeTabVisible:false,
      // jwtToken:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3NDA5ODM3NjgsImtleSI6IjM2NjkwMjE0MDc0MjAxMzI5NyIsImV4cGlyZXNfaW4iOjYwNDgwMH0.Qb4vd92UoTT-cEJFfQy6sY1Vu-_aHf9jHK6OvMr5csc',
      jwtToken:null,
      locale:'zh_TW',
      select_theme:null,

    };
  },
  mounted: function(){
    this.jwtToken=getAuthor();
    this.locale=getCareLang();
    let that=this;
    window.addEventListener('message',function (event) {
      if(event.data?.data&&event.data?.origin==="subject"){
        that.select_theme=JSON.parse(event.data.data);
        that.themeForm.theme=that.select_theme.subject_content;
        that.themeTabVisible=false;
      }
    });
    this.$nextTick(function () {
      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight = document.documentElement.clientHeight - 100;
      }
    });
    this.getList();

  },
  components: {
    TplCard,
  },
  watch:{
    'form.footer_test'(){
      if(this.form.footer_test)
      {
        let parms=this.getBodyTextDynamicParms(this.form.footer_test);
        if(parms.length){
          this.showFootParmsErr=true;
          return;
        }
      }
      this.showFootParmsErr=false;
    },
    'form.name'(){
      this.checkName();
    },
    'form.body_text'(){
      this.checkBodyParms();
    },
    'form.parameter_format'(){
      this.checkBodyParms();
      this.checkHeaderParms();
    },
    'form.componentsObj.HEADER.text'(){
      this.checkHeaderParms();
    }
  },
  computed: {
    parmmappingTplParms(){
      if(this.parmmappingRow){
        let rs=[];
        const tpl= this.parmmappingRow;
        rs= rs.concat(tpl.header_parms?JSON.parse(tpl.header_parms):[],tpl.body_parms?JSON.parse(tpl.body_parms):[]);
        return  [...new Set(rs)];
      }
      return null;
    },
  },
  methods: {
    onBtnTypeChange(index,val){
      if(val=='STOP_PROMOTIONS'){
        this.form.bottons[index].text=this.$t('care.tpls.stop_promotions');
      }
    },
    getPromptKey(){
      const userid= sessionStorage.getItem('userid');
      return `yme:${userid}:whatsapp_tpl_prompt`;
    },
    getPrompt(){
      const cache=sessionStorage.getItem(this.getPromptKey())
      if(cache) return cache;
      return '        你是一个whatsapp模板生成机器人，你根据我们提供的主题内容和地区语言生成WhatsApp模板的主要内容，必须以类似于接口json数据的格式返回；\n' +
          '        必须输出以下字段：name,header_txt,body_txt,foot_txt;\n' +
          '        \n' +
          '        ****This section allows changes**** \n' +
          '        header_txt，body_txt均可以设置动态参数，如{{parameter}}表示插入名为parameter的动态参数；\n' +
          '        header_txt最多只允许一个动态参数，body_txt允许0到多个动态参数；\n' +
          '        name只能由小写字母和数字和下划线组成且name应该简短(50字符以内)且与主题紧密相关;\n' +
          '        header_txt和foot_txt最长不能超出60字符;\n'+
          '        name字段后面加2位数随机数。';
    },
    async onCreateWhatsappTpl(){
     const userid= sessionStorage.getItem('userid');
     const rs= await postCreateWhatsappTplAI(this.group_id,{
       ...this.themeForm,
       lang:this.form.language,
       uid:userid,
       messenger_provider:'whatsapp',
     });
     const obj=JSON.parse(rs.result)
     this.form.name=obj.name;
     this.form.componentsObj.HEADER_TYPE='TEXT'
     this.form.componentsObj.HEADER.text=obj.header_txt;
     this.form.body_text=obj.body_txt;
     this.form.footer_test=obj.foot_txt;
      sessionStorage.setItem(this.getPromptKey(),this.themeForm.prompt)
    },
    onCancelSaveParmmappings(){
      this.parmmappingVisible=false;
      this.parmmappingForm={};
      this.parmmappingRow=null;
    },
    async onSaveParmmappings(){
      try {
        const mappings=[];
        for (const [key, value] of Object.entries(this.parmmappingForm)) {
          mappings.push({
            'key':key,
            'label':value,
            'type':'string'
          })
        }
        await pullTplParmMappings(this.messenger_id,this.parmmappingRow.id, {parm_mappings:mappings});
        this.parmmappingRow.parm_mappings=JSON.stringify(mappings);
        this.parmmappingForm={};
      }catch (e) {
        this.$message.error('保存出错')
      }finally {
        this.parmmappingVisible=false;
      }

    },
    onEditParmMappings(row){
      this.parmmappingRow=row;
      console.log(row)
      if(!this.parmmappingRow.body_parms&&!this.parmmappingRow.header_parms){
        this.$message.warning('当前模板无参数可以设置')
        return;
      }
      if(this.parmmappingRow.parm_mappings&&this.parmmappingRow.parm_mappings!=='null'){
        let form={};
        let marppings=JSON.parse(this.parmmappingRow.parm_mappings);
        for (let i = 0; i <  marppings.length; i++) {
          const marpping= marppings[i];
          form[marpping['key']]=marpping['label'];

        }
        this.parmmappingForm=form;
      }
      this.$nextTick(function () {
        this.parmmappingVisible=true;
      })
    },
    async onSaveAlias(){
      await pullMessengerTplAlias(this.messenger_id,this.editTpl.id,this.editForm);
      this.editTpl.alias=this.editForm.alias;
      this.editVisible=false;

    },
    onEditAlias(row){
      this.editTpl=row;
      this.editVisible=true;
      this.editForm.alias=row.alias;
    },
    onNestStep(){
      if(this.form.category==='AUTHENTICATION'){
        this.$message.warning(this.$t('care.tpls.msg_disuseable'))
        return;
      }
      this.add_step++
    },
    initForm(){
      this.form=this.getDefForm();
      this.add_step=0;
    },
    getDefForm(){
      return {
        language: "zh_HK",
        category: "MARKETING",
        parameter_format: 'NAMED',
        componentsObj: {
          HEADER_TYPE: null,
          FOOTER_TYPE: null,
          HEADER: {
            text: '',
            example: {
              header_handle_src: ''
            }
          },
          FOOTER: null,
          BUTTONS: [],
          BODY: {
            text: "你好{{name}},我係TopkeeMedia的顧問，很高興可以為你服務，請問有什麼可以幫到你？",
            type: "BODY",
            example: {
              body_text_named_params: [],
              body_text: [],
            }
          },
        },
        header: null,
        name: null,
        body_text: this.$t('care.tpls.default_body_txt'),
        body_example: "我想向你確認一下，你是否已經收到我們的服務報價，想問你對我們的方案報價，有沒有疑問需要我進一步解答",
        footer_test: null,
        bottons: [
          {
            "type": "STOP_PROMOTIONS",
            "text": this.$t('care.tpls.stop_promotions')
          },{
            "type": "QUICK_REPLY",
            "text": this.$t('care.tpls.default_btns_consulting_program')
          }
        ],

      };
    },
    containsChineseOrSpecialChars(filename) {
      // Unicode 范围 [\u4e00-\u9fa5] 匹配所有汉字
      // 特殊字符可以根据需要定义，这里使用了一个简单的示例集合
      const regex = /[\u4e00-\u9fa5]/; // \W 匹配任何非单词字符（等价于 [^a-zA-Z0-9_]）
      // 测试文件名是否匹配正则表达式
      return regex.test(filename);
    },
    async onUpload({ file, onProgress, onSuccess, onError }){
     if(this.containsChineseOrSpecialChars(file.name)){
       this.$message.error(this.$t('care.tpls.file_has_err_char'));
       onError();
       return;
     }

      const rs=await whatsappResumableUpload(this.messenger_id,file);
      if(rs.success){
        this.form.componentsObj.HEADER.example.header_handle_src=rs.handle;
        this.$message.success(this.$t('care.opsSuccess'));
      }else{
        onError();
        this.$message.error(this.$t('care.tpls.upload_fail'));
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`${this.$t('care.tpls.remove_file')} ${ file.name }？`);
    },
    handleExceed(file, fileList) {
      this.$message.warning(this.$t('care.tpls.file_limit_tips'));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.form.componentsObj.HEADER.example.header_handle_src=null;
    },
    checkName(){
     const regex = /^[a-z\d_]+$/;
     if(this.form.name.indexOf('test')>-1){
        this.showNameError =true;
        return;
     }
     if(this.form.name===''||regex.test(this.form.name)){
       this.showNameError=false;
     }else {
       this.showNameError=true;
     }
    },
    checkBodyParms: function () {
      let parms=this.getBodyTextDynamicParms(this.form.body_text);
      this.body_parms=parms;
      if(parms.length)
      {
        let hasErr=false;
        const regex = /^[a-z0-9_]+$/;
        const intRegex = /^[+]?\d+$/;
        for (let i = 0; i < parms.length; i++) {
          let parm=parms[i];
          if(parm===''){
            hasErr=true;
          }else if(!regex.test(parm)){

            hasErr=true;
          }else if(this.form.parameter_format==='NAMED'){
            if(intRegex.test(parm)){
              hasErr=true;
            }
          }else{
            if(!intRegex.test(parm)){
              hasErr=true;
            }
          }
        }
        this.showBodyParmsErr=hasErr;
        if(this.showBodyParmsErr){
          this.body_parms=[];
        }
      }else{
        this.showBodyParmsErr =false;
      }


    },
    checkHeaderParms: function () {
      let parms = this.getBodyTextDynamicParms(this.form.componentsObj.HEADER.text);
      this.header_parms = parms;
      if (parms.length) {
        let hasErr = false;
        if (parms.length > 1) {
          hasErr = true;
        } else {
          const regex = /^[a-z0-9_]+$/;
          const intRegex = /^[+]?\d+$/;
          for (let i = 0; i < parms.length; i++) {
            let parm = parms[i];
            if (parm === '') {
              hasErr = true;
            } else if (!regex.test(parm)) {

              hasErr = true;
              break;
            } else if (this.form.parameter_format === 'NAMED') {
              if (intRegex.test(parm)) {
                hasErr = true;
                break;
              }
            } else {
              if (!intRegex.test(parm)||parseInt(parm)!==1) {
                hasErr = true;
                break;
              }
            }
          }
        }
        this.showHeaderParmsErr = hasErr;
        if (this.showHeaderParmsErr) {
          this.header_parms = [];
        }
      }else{
        this.showHeaderParmsErr =false;
      }
    },
    getSelectedText(){
      const inputElement = this.$refs.textArea.$el.querySelector('textarea');
      const start = inputElement.selectionStart;
      const end = inputElement.selectionEnd;
      const text = inputElement.value;
      let selectedText='';

      if (start !== end) {
        selectedText = text.substring(start, end);
      } else {
        selectedText = '';
      }
     return {
       start,
       end,
       selectedText,
     }
    },
    getBodyTextDynamicParms(input){
      // 正则表达式匹配双花括号中的内容
      let regex = /\{\{(.*?)\}\}/g;
      let matches;
      let result = [];

      // 使用正则表达式执行全局匹配，并收集所有匹配结果
      while ((matches = regex.exec(input)) !== null) {
        result.push(matches[1]);
      }
      return result;

    },
    body_text_parms: function () {
      const {start, end, selectedText} = this.getSelectedText();
      let body_text = this.form.body_text;
      // 获取开始之前的部分
      const before = body_text.slice(0, start);
      // 获取结束之后的部分
      const after = body_text.slice(end + 1);
      return {selectedText, body_text, before, after};
    },
    onTextBold(){
      let {selectedText, body_text, before, after} = this.body_text_parms();
      const middle=`*${selectedText}*`;
      body_text=before +middle + after;
      this.form.body_text=body_text;
    },
    onTextItalics(){
      let {selectedText, body_text, before, after} = this.body_text_parms();
      const middle=`_${selectedText}_`;
      body_text=before +middle + after;
      this.form.body_text=body_text;
    },
    onTextDeleted(){
      let {selectedText, body_text, before, after} = this.body_text_parms();
      const middle=`~${selectedText}~`;
      body_text=before +middle + after;
      this.form.body_text=body_text;
    },
    onTextdynamicParm(part){
      let middle='';
      console.log(part)
      if(part==='body') {
        let {selectedText, body_text, before, after} = this.body_text_parms();
        middle=`${selectedText}{{${this.$t('care.tpls.your_param_name')}}}`;
        body_text=before +middle + after;
        this.form.body_text=body_text;
      }else{
        middle=`{{${this.$t('care.tpls.your_param_name')}}}`;
        this.form.componentsObj.HEADER.text=this.form.componentsObj.HEADER.text+middle;
      }



    },
    onTextCoded(){
      let {selectedText, body_text, before, after} = this.body_text_parms();
      const middle=`\`\`\`${selectedText}\`\`\``;
      body_text=before +middle + after;
      this.form.body_text=body_text;
    },
    addBtn(){
      this.form.bottons.push({
        "type": "QUICK_REPLY",
        "text": this.$t('care.tpls.default_btns_i_see')
      });
    },
    deleteBtn(index){
      this.form.bottons.splice(index,1);
    },
    onDblclick(row){
      this.cardVisible=false;
      this.viewRow={}
      this.$nextTick(function () {
        console.log(row,'---------')
        this.viewRow=row.data
        this.cardVisible=true;
      })

    },
    onSearch(){
      this.page=1;
      this.getList();
    },
    pageChange(page){
      this.page=page;
      this.getList();
    },
    editCare(row){
      this.careForm=row;
      this.addCareVisible=true;
    },
    async save(){
      try {


        if(
            !this.form.body_text
            ||!this.form.name
        ){
          this.$message.warning(this.$t('care.messenger.messenger_add_parms'))
          return;
        }
        if(this.showNameError||this.showHeaderParmsErr||this.showBodyParmsErr||this.showFootParmsErr){
          this.$message.warning(this.$t('care.tpls.messenger_parms_err'))
          return;
        }
        let data={};
        data.name=this.form.name;
        data.language=this.form.language;
        data.category='MARKETING';
        data.components=[];
        let body=null;
        if(this.form.parameter_format==='NAMED'){
          body= {
            "type": "BODY",
            "text": this.form.body_text,

          }
          const body_text_named_params= this.body_parms.map(item=>{
            return  {
              "example": `params_${item}`,
              "param_name": item
            };
          });
          if(body_text_named_params.length){
            body['example']={
              "body_text_named_params": body_text_named_params
            };
          }

        }else{
         const body_text= this.body_parms.map(item=>{
            return `params_${item}`;
          });
          body= {
              "type": "BODY",
              "text": this.form.body_text,
          }
          if(body_text.length){
            body['example']={
              "body_text": [body_text]
            };
          }
        }
        data.components.push(body);
        if(this.form.componentsObj.HEADER_TYPE){
          let header=null;
          if(this.form.componentsObj.HEADER_TYPE==='TEXT'){
            if(this.form.componentsObj.HEADER.text.trim()===''){
              this.$message.error(this.$t('care.tpls.header_file_missing'));
              return;
            }
            if(this.header_parms.length){
              if(this.form.parameter_format==='NAMED'){
                let header_text_named_params =this.header_parms.map(item=>{
                  return {
                    "example": `params_${item}`,
                    "param_name": item
                  };
                });
                header= {
                  "type": "HEADER",
                  "format": this.form.componentsObj.HEADER_TYPE,
                  "text": this.form.componentsObj.HEADER.text,
                }
                if(header_text_named_params.length){
                  header['example']={
                    "header_text_named_params": header_text_named_params
                  }
                }
              }else{
                let header_text =this.header_parms.map(item=>{
                  return `params_${item}`;
                });
                header= {
                  "type": "HEADER",
                  "format": this.form.componentsObj.HEADER_TYPE,
                  "text": this.form.componentsObj.HEADER.text,
                }
                if(header_text.length){
                  header['example']={
                    "header_text": header_text
                  }
                }
              }

            }else{
              header= {
                "type": "HEADER",
                "format": this.form.componentsObj.HEADER_TYPE,
                "text": this.form.componentsObj.HEADER.text,
              }
            }

          }else{
            if(['IMAGE','VIDEO','DOCUMENT'].indexOf(this.form.componentsObj.HEADER_TYPE)>-1){
              if(!this.form.componentsObj.HEADER.example.header_handle_src){
                this.$message.warning('請上傳頁首文件資源');
                return ;
              }
              header= {
                "type": "HEADER",
                "format": this.form.componentsObj.HEADER_TYPE,
              }
              header['example']= {
                "header_handle": [this.form.componentsObj.HEADER.example.header_handle_src]
              }
            }else{
              header= {
                "type": "HEADER",
                "format": this.form.componentsObj.HEADER_TYPE,
              }
            }
          }
          data.components.push(header);
        }

        if(this.form.footer_test){
          data.components.push({
            "type": "FOOTER",
            "text": this.form.footer_test,
          })
        }
        if(this.form.bottons.length){
          data.components.push({
            "type": "BUTTONS",
            "buttons": [].concat(this.form.bottons.map(item=>{
              if(item.type==='STOP_PROMOTIONS'){
                item.type='QUICK_REPLY'
              }
              return item;
            })),
          })
        }
        data.parameter_format=this.form.parameter_format;
        console.log(JSON.stringify(data),4)
        console.log(data)
        this.addLoading=true;
        this.add_step++;
        await addMessengerTpl(this.messenger_id,data);
        this.addLoading=false;
        this.addCareVisible=false;
        message.success(this.$t('care.opsSuccess'));
        this.initForm();
        this.handlePull();
      }catch (e) {
        if(this.add_step===2){
          this.add_step--;
        }
        this.addLoading=false;
        const err=e.data?.message||'error';
        this.$message.error(err)
        throw e;
      }

    },
    async getList(){
      this.spinning=true;

      const rs=await getMessengerTplsByPage(this.messenger_id,{
        filter:this.search,
        page:{
          number:this.page,
          size:this.pageSize,
        },
        sort:['-updated_at']
      });
      this.tableData = rs.data;

      this.total= rs.total;
      this.spinning=false;
    },
    handleAdd(){
      // this.careForm={
      //   id:null,
      //   name:null,
      //   avatar:null,
      //   receive_able:0,
      // };
      this.addCareVisible=true;
    },
    async handlePull(){
      try {
        message.info(this.$t('care.tpls.doing_sync_tpl'))
        this.pulling=true;
        await pullMessengerTpls(this.messenger_id);
        this.pulling=false;
        message.success(this.$t('care.tpls.down_sync_tpl'))
      }catch (e) {
        const err=e.data?.message||'error';
        this.$message.error(err)
        throw e;
      }finally {
        this.pulling=false;
        this.onSearch();
      }

    },

    handleDelete(index,row){
      let that=this;
      this.$confirm(this.$t('care.delete_tpl_tips'), this.$t('care.tips'), {
        confirmButtonText: this.$t('care.messenger.sure'),
        cancelButtonText: this.$t('care.cancel'),
        type: 'warning'
      }).then(async () => {
        row.delete_loading=true;
        await deleteMessengerTpl(that.messenger_id,row.id)
        this.$message({
          type: 'success',
          message: this.$t('care.opsSuccess')
        });
        row.delete_loading=false;
        that.handlePull();
      })
    },
  },
  mixins:[careMixin]
};
</script>
<style scoped lang="less">
.tpl_ed_warp{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  border: 1px solid #ccc;
  overflow-x: auto;
  overflow-y: hidden;
}
.selected{
  background-color: #e0e0e0;
}
.foot{
  margin-top: 40px;
}
.foot_btns{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.textarea_tools{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  &>div{
    cursor: pointer;
    padding: 0 17px;
    &:hover{
      background-color: #efefef;
    }
  }
}
</style>
